import {useState, useEffect} from  'react'
import IgnoreCompanyButton from '../ignoreCompanies/IgnoreCompanyButton'
import SendMailButton from '../sendMail/SendMailButton'
import {useHistory} from 'react-router-dom';

const NewCompanyList = () => {
    const [newCompanyInfos, setNewCompanyInfos] = useState([])
    const history = useHistory()

    useEffect(() => {
        async function fetchData() {
            let token = localStorage.getItem('MAIL_ADS_TOKEN')
            if (token == null) {
                history.push('/login');
                return;
            }

            let headers = new Headers({'Authorization': `Bearer ${token}`})
            let result = await fetch(window['MAIL_ADS_API_URL'] + '/newcompanies', {headers: headers})

            if (result.status === 401) {
                history.push('/login');
                return;
            }
            let newCompanyInfos = await result.json()
            setNewCompanyInfos(newCompanyInfos)
        }
        fetchData();
      }, [history])

    return (
        <ul className="list-group mt-4">
            {newCompanyInfos.map((newCompanyInfo) => (
                    <div id={'ID' + newCompanyInfo.id} key={newCompanyInfo.id}>
                        <li className="list-group-item d-flex"><span className="p-0 m-0 flex-grow-1">{newCompanyInfo.companyName} {newCompanyInfo.insertDate}</span><SendMailButton id = {newCompanyInfo.id}/><IgnoreCompanyButton id = {newCompanyInfo.id}/></li>
                    </div>
            ))}
        </ul>
    )
}

export default NewCompanyList

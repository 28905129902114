import {useState} from 'react'
import {useHistory} from 'react-router-dom';

const LoginComponent = () => {
    const [userName, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const history = useHistory();

    const login = async (e) => {
        e.preventDefault()
        let userData = JSON.stringify({userName: userName, password: password})
        let response = await fetch(window['MAIL_ADS_API_URL'] + '/login', {headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },method: 'POST', body: userData})

        if (response.status === 200) {
            let obj = await response.json()
            let token = obj.token
            localStorage.setItem('MAIL_ADS_TOKEN', token)
            history.push('/')
        }
    }
    return (
        <form onSubmit={login} className="mt-4">
            <div className="mb-4">
                <input onChange={e => setUsername(e.target.value)} type="text" className="form-control form-control-lg" placeholder="Username" />
            </div>

            <div className="mb-4">
                <input onChange={e => setPassword(e.target.value)} type="password" className="form-control form-control-lg" placeholder="Password" />
            </div>

            <div>
                <button type="submit" className="btn btn-primary btn-lg">Login</button>
            </div>
        </form>
    )
}

export default LoginComponent

import './App.css';
import NewCompanyList from './Features/displayNewCompanies/NewCompanyList';
import LoginComponent from './Features/login/LoginComponent';
import { BrowserRouter, Switch, Route} from 'react-router-dom';

function App() {

  return (
    <div className="container">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={NewCompanyList} />
          <Route path="/login" component={LoginComponent} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { useEffect } from "react"
import {useHistory} from 'react-router-dom';

const IgnoreCompanyButton = ({id}) => {
    const history = useHistory()

    useEffect(() => {
        let classes = document.getElementById('ID' + id).classList;
        classes.add('collapse')
        classes.add('show')
    });

    const ignoreCompanyInBackend = async () => {
        let token = localStorage.getItem('MAIL_ADS_TOKEN')
        let headers = new Headers({'Authorization': `Bearer ${token}`})
        let response = await fetch(window['MAIL_ADS_API_URL'] + '/ignorecompany/' + id, {method: 'POST', headers: headers})

        if (response.status === 401) {
            history.push('/login');
            return;
        }
    }

    return (
        <button onClick={ignoreCompanyInBackend} type="button" className="btn btn-danger m-1" data-bs-toggle="collapse" data-bs-target={'#ID' + id}>Ignore</button>
    )
}

export default IgnoreCompanyButton
